import { useRef, useState } from 'react';
import { Menu, MenuItem, SubMenu,  useHover, useMenuState } from "@szhsin/react-menu";

import { useNavigate } from "react-router-dom";
import "@szhsin/react-menu/dist/index.css";

const NavbarMenu = ({ menuData }) => {

  const navigate = useNavigate();
  const ref = useRef(null);  // Ref for each menu container
  const [isOpen, setOpen] = useMenuState(false); // State to control whether menu is open

  // Using useHover to handle hover state and automatically open/close the menu
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);

  // Render submenu items recursively
  const renderSubMenuItems = (subMenu) => {
    return subMenu.subMenu && subMenu.subMenu.length > 0 ? (
      subMenu.subMenu.map((subSubMenu) => (
        <MenuItem
          key={subSubMenu.menu_id}
          onClick={() => navigate(subSubMenu.url)}
          style={{
            fontFamily: "Roboto",
            fontWeight: "300",
            fontSize: "16px",
            // padding:'0.2rem'
            // color: "#005F3B",
          }}
        >
          {subSubMenu.menu_name}
        </MenuItem>
      ))
    ) : null; // Don't render anything if no subitems
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", gap: 20 }}>
      {menuData.map((menu) => (
        <div
          key={menu.menu_id}

          ref={ref}  // Attach the ref to the container for hover actions
          {...anchorProps}  // Attach the anchorProps from useHover
          
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "5px",
            fontFamily: "Roboto",
            fontWeight: "300",
            cursor: "pointer",
          }}
        >
          <Menu
          
           {...hoverProps}  // Attach hoverProps to manage hover state
            menuButton={<div>{menu.menu_name}</div>}
            position="bottom"
            align="start"
            open={isOpen} 
          >

            {/* {console.log(`Hovered Menu: ${hoveredMenu}, Current Menu: ${menu.menu_id}`)} */}

            {/* Only render the SubMenu if it has actual subitems */}
            {menu.subMenu && menu.subMenu.length > 0 ? (
              menu.subMenu.map((subMenu) => (
                // Only show SubMenu if it has items
                subMenu.subMenu && subMenu.subMenu.length > 0 ? (
                  <SubMenu key={subMenu.menu_id} label={subMenu.menu_name}>
                    {renderSubMenuItems(subMenu)} {/* Render subitems recursively */}
                  </SubMenu>
                ) : (
                  <MenuItem
                    key={subMenu.menu_id}
                    onClick={() => navigate(subMenu.url)}
                  >
                    {subMenu.menu_name}
                  </MenuItem>
                )
              ))
            ) : (
              <MenuItem onClick={() => navigate(menu.url)}>
                {menu.menu_name}
              </MenuItem>
            )}
          </Menu>
        </div>
      ))}
    </div>
  );
};

export default NavbarMenu;
