import React, { useEffect, useState } from "react";
import { server } from "../config/server.js";
import ViewCount from "../components/ViewCount.js";
import logistikMain from "../assets/logistikMain.png";
import { useLocation, useParams, useNavigate } from "react-router-dom";

export default function BisnisPerusahaan() {
  const [data, setData] = useState([]);
  const location = useLocation();
  const { content_id } = location.state || {};
  const { menu } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(content_id, "kontens id");

    // Redirect if content_id is 13
    // if (content_id === 13) {
    //   navigate("/profil/manajemen");
    //   return;
    // }

    switch (content_id) {
      case 13:
        return navigate("/profil/manajemen");
      case 27:
        return navigate("/whistleblower");
      case 52:
        return navigate("/permohonan-informasi");
      case 53:
        return navigate("/permohonan-keberatan-informasi");
      case 80:
        return navigate("/pengadaan_eproc");
      case 81:
        return navigate("/pengadaan_eproc");
      default:
        break;
    }

    const getData = async () => {
      try {
        const response = await fetch(
          `${server}/api/v1/content/list/${content_id ? content_id : menu}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const result = await response.json();
        console.log(result.data);

        setData(result.data);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
    window.scrollTo(0, 0);
  }, [content_id]);

  const backgroundImage =
    data.length > 0 && data[0]?.content_header_link !== `N/A`
      ? data[0].content_header_link?.replace(/ /g, "%20")
      : null;
  const contentHTML =
    data.length > 0 && data[0].content_html
      ? data[0].content_html
      : "<p>No Content Available";

  return (
    <>
      <img
        class="w100"
        style={{ borderRadius: 20 }}
        src={backgroundImage}
      ></img>
      <a>
        <div className="pageTitle">
          {data.length > 0 ? data[0].content_title : "Loading..."}
        </div>
      </a>
      <div className="articleContainer pageText" style={{ display: "block" }}>
        <div className="list image" style={{ textAlign: "justify" }}>
          <div
            dangerouslySetInnerHTML={{ __html: `<a>${contentHTML}</a>` }}
          ></div>
        </div>
      </div>
      <ViewCount count={data.length > 0 ? data[0].views : 0} />
    </>
  );
}
