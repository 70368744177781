import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { server } from "../../config/server.js";
import ViewCount from "../../components/ViewCount.js";

export default function SejarahSingkat() {
  const navigate = useNavigate();


  const [dataMain, setDataMain] = useState([]); // Always holds main content
  const [dataTab, setDataTab] = useState([]); // Holds tab-specific content
  const [selectedTab, setSelectedTab] = useState("struktur"); // Default active tab
  const location = useLocation();
  const page = location.pathname.split("/")[3];
  useEffect(() => {
    // Fetch main content
    const getMainContent = async () => {
      try {
        const response = await fetch(`${server}/api/v1/content/list/13`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });
        const result = await response.json();
        setDataMain(result.data);
      } catch (err) {
        console.log(err);
      }
    };

    getMainContent();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Fetch tab-specific content
    console.log(page,'tet');
    
    const getTabContent = async () => {
      try {
        setSelectedTab(page)
        let contentNo = "";
        console.log('selectedTab')
        switch (page) {
          case "struktur":
            contentNo = 38;
            break;
          case "komisaris":
            contentNo = 39;
            break;
          case "direksi":
            contentNo = 40;
            break;
          default:
            contentNo = 38; // Fallback
        }

        const response = await fetch(`${server}/api/v1/content/list/${contentNo}`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        });

        const result = await response.json();
        setDataTab(result.data);
      } catch (err) {
        console.log(err);
      }
    };

    getTabContent();
    window.scrollTo(0, 0);
  }, [page]); // Fetch only when tab changes

  const backgroundImage =
    dataMain.length > 0 ? dataMain[0].content_header_link.replace(/ /g, "%20") : null;

  return (
    <>
      <img className="w100" style={{ borderRadius: 20 }} src={backgroundImage} alt="Background" />
      <div className="pageTitle">{dataMain.length > 0 ? dataMain[0].content_title : "Loading..."}</div>

      {/* Tabs Section */}

      <div className="articleContainer pageText">
        <div className="list image">
          <div className="flex" style={{ gap: 4 }}>
            {["struktur", "komisaris", "direksi"].map((tab) => (
              <div
                key={tab}
                className={`tabMenu ${selectedTab === tab ? "tabActive" : ""}`}
                id={tab}
                onClick={() => {
                  setSelectedTab(tab);
                  navigate(`/profil/manajemen/${tab}`);
                }}
              >
                <div className="tabName px-4 py-2">
                  {tab === "struktur" ? "Struktur Organisasi" : tab.charAt(0).toUpperCase() + tab.slice(1)}
                </div>
                <div className="tabUnderline"></div>
              </div>
            ))}
          </div>
        </div>

        {/* Display Main Content */}


        {/* Display Tab-Specific Content */}
        <div className="articleContainer pageText">
          <div className="list image" style={{ textAlign: "justify" }}>
            {dataTab.length > 0 ? (
              <div dangerouslySetInnerHTML={{ __html: dataTab[0].content_html }}></div>
            ) : (
              <p>No {selectedTab} Content Available</p>
            )}
          </div>
        </div>

        <ViewCount count={dataMain.length > 0 ? dataMain[0].views : 0} />
      </div>
    </>
  );
}
